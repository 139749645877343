import React from "react";
import { ISearchField, ISearchProp } from "../Types";
import { DatePicker } from "antd";
import moment from "moment";

type PropType = ISearchProp & ISearchField;
export const SearchDatepicker = (props: PropType) => {
    return <DatePicker
        className="ant-picker-round"
        placeholder={props.placeholder as string}
        format={props.format || "YYYY-MM-DD"}
        value={props?.searchValues?.[props.fieldName] ? moment(props?.searchValues?.[props.fieldName]) : undefined}
        showTime={props.showTime}
        onChange={(value, timeStr) => {
            props.onChange?.({ [props.fieldName]: timeStr });
            if (props.triggerSearch) props.handleSearch?.();
        }}
        disabled={props.disabled}
        showNow={false}
    />
}