import React, { useEffect } from "react";
import { useNewsDetailModel } from "./DetailModel";
import { InfoBox } from "./InfoBox";
import { YhPageHeader } from "../../../components/YhPageHeader";
import { useNewsSummaryModel } from "../summary/SummaryModel";
import { EditModeEnum } from "../../../types/FmsEnums";

export const NewsDetail = () => {
    const {
        setSelectedRowId,
        selectedData,
    } = useNewsSummaryModel()

    const {
        editMode,
    } = useNewsDetailModel();


    useEffect(() => {
        if (editMode === EditModeEnum.add) {
            setSelectedRowId('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            <YhPageHeader title="文章详情" />
            <InfoBox />
        </>
    )
}
