import React from "react";
import { ISearchField, ISearchProp } from "../Types";
import { Select } from "antd";

type PropType = ISearchProp & ISearchField;
export const SearchSelect = (props: PropType) => {
    return <Select
        allowClear
        className="ant-select-round"
        value={props.searchValues?.[props.fieldName]}
        placeholder={props.placeholder}
        onChange={(value) => {
            props.onChange?.({ [props.fieldName]: value })
            if (props.triggerSearch) props.handleSearch?.();
        }}
        disabled={props.disabled}
        options={props.options}
    />
}