import React, { FC, useEffect, useImperativeHandle } from "react";
import { Form, Row } from "antd";
import { IYhForm } from "./Types";
import { __FormItem as FormItem } from "./item";


export const __YhForm: FC<IYhForm> = ({ formRef, items, ...props }) => {
    const [form] = Form.useForm();
    const { labelCol, labelAlign, disabled, initialValues, formValues, onChange, onFinish, children } = props
    const onValuesChange = (changedValues: any, values: any) => {
        //disabled ? form.setFieldsValue(formValues) : onChange?.(changedValues);
        onChange?.(changedValues);
    }
    useEffect(() => {
        // form.resetFields();
        form.setFieldsValue(initialValues);
    }, [initialValues, form]);

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue(formValues);
    }, [formValues, form]);

    useImperativeHandle(formRef, () => ({
        validateFields: async () => {
            return await form.validateFields();
        },
        getFieldsValue: () => {
            return form.getFieldsValue()
        },
        setFieldsValue: (formValues) => {
            form.setFieldsValue(formValues);
        },
        resetFields: form.resetFields,
        inst: () => {
            return form;
        }
    }));
    return <Form
        form={form}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        labelCol={labelCol}
        labelAlign={labelAlign}
    >
        <Row
            gutter={[20, 10]}
            justify={props.justify ?? 'center'}
        >{
                items.map((el, index) => {
                    return <FormItem key={index} {...el} disable={el.disable ?? disabled} />
                })
            }
            {
                children
            }
        </Row>
    </Form>;
}