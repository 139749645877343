import { httpRequest } from "../../utils/HttpRequest";
import { AuthUser, IUserFields } from "./LoginTypes";

class LoginService {
    //根据token拿到用户信息
    async getUser(params: { token: string; }) {
        const apiUrl = "/portal/auth/login/verify";
        return await httpRequest
            .post<IUserFields>(apiUrl, params);
    }

    //账号密码登录
    async userLogin(user: AuthUser) {
        const apiUrl = "/portal/auth/login/login";
        return await httpRequest
            .post<string>(apiUrl, user);
    }
    /** 退出登录 */
    async userLogout() {
        const apiUrl = "/portal/auth/login/logout";
        return await httpRequest
            .post<string>(apiUrl)
    }

    /**
     * 用户登入日志记录
     * @returns 
     */
    async appLogin(userCode: string) {
        const logUrl = `/portal/log/appAccess/recordAppLoginInfo/zqbx/${userCode}`;
        return await httpRequest.post<Boolean>(logUrl);
    }

    /**
     * 用户登出日志记录
     * @returns 
     */
    async appLogout(userCode: string) {
        const logUrl = `/portal/log/appAccess/recordAppLogoutInfo/zqbx/${userCode}`;
        return await httpRequest.post<Boolean>(logUrl);
    }

}

export const loginService = new LoginService();
