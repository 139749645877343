import { globalPrompt } from "@src/components/YhMessage";
import { loadFileUrl } from "@src/utils/constants";
import { UploadFile } from "antd/lib/upload/interface";
import { createModel } from "hox";
import { useState } from "react";
import { resourceService } from "./ResourceService";

type ResourceType = 'image' | 'video'
export const useResourceModel = createModel(function () {

    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [videoUrl, setVideoUrl] = useState<string>("")
    /**
     * 加载数据
     * @param page 跳转页面信息
     */
    const loadData = async (type: ResourceType) => {
        try {
            const { retData } = await resourceService.find(type === 'image' ? 0 : 1)
            const list = retData?.source?.map((item, index) => {
                return {
                    uid: item,
                    name: type === "image" ? "00" + (index + 1) + ".png" : "视频",
                    status: 'done',
                    url: type === "image" ? loadFileUrl(item) : "",
                }
            }) as UploadFile[]
            setFileList(list)
            if (type === "video" && retData?.source?.length === 1) setVideoUrl(retData.source[0])

        } catch (error) {
            console.log("error: ", error)
        }
    }

    //表单提交
    const onUpdate = async (type: ResourceType) => {
        try {
            const params = {
                source: type === 'image' ? fileList.map(item => item.uid) : [videoUrl],
                type: type === 'image' ? 0 : 1
            }
            const { retData, retMsg } = await resourceService.update(params)
            if (retData) {
                globalPrompt('message', { type: 'success', text: '修改成功！' })
            } else {
                globalPrompt('message', { type: 'warning', text: '修改失败！' + retMsg })
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }


    return {
        /***field***/
        fileList,
        videoUrl,

        /***method***/
        setFileList,
        loadData,
        onUpdate,
        setVideoUrl,
    }
});
