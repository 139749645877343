import { IPage } from "../../types/FmsTypes";
import { httpRequest } from "../../utils/HttpRequest";
import { IMessageFields, IMessageSearch } from "./MessageTypes";

class MessageService {
    /**
     * 分页查询
     * @param param 
     * @returns 
     */
    page(param: IMessageSearch) {
        const apiUrl = "/zqbx_api/message/page";
        return httpRequest
            .post<IPage<IMessageFields>>(apiUrl, param);
    }
    /**
     * 新增
     * @param param 
     * @returns 
     */
    insert(param: IMessageFields) {
        const apiUrl = "/zqbx_api/message/insert";
        return httpRequest
            .post<boolean>(apiUrl, param);
    }

}

export const messageService = new MessageService();
