import React, { FC } from "react";
import { IFormItem } from "../Types";
import { Form, Input } from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons"

export const __FormPassword: FC<IFormItem> = (props) => {
    return (
        <Form.Item
            label={props.fieldLabel}
            name={props.fieldName}
            rules={props.rules}
            hidden={props.hidden}
            labelCol={props.labelCol}
        >
            <Input.Password
                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                disabled={props.disable ?? false}
                allowClear={props.allowClear}
            />
        </Form.Item>
    )
}