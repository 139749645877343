import React, { FC, useEffect } from "react";
import { message } from "antd";
import useGlobalModel from "../../model/globalModel";
import { useHistory } from "react-router-dom";
import "./Login.scss";
import { loginService } from "./LoginService";
import { CLIENT_APP_CODE } from "@src/utils/constants";
import useNavModel from "@src/model/navModel";
import MenuData from "../../data/menuData.json"

interface ITokenLogin {
  token: string;
}
export const TokenLogin: FC<ITokenLogin> = ({ token }) => {
  const history = useHistory()
  const { user, setUser, menuData, setMenuData } = useGlobalModel();
  const { addNav } = useNavModel();

  useEffect(() => {
    beforeLogin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  useEffect(() => {
    let firstNav = menuData.filter(item => item.isShow === 0)[0]
    if (!firstNav) {
      addNav("ff-01")
      user.token && history.push('/message')
      return
    }
    if (user.token) {
      addNav(firstNav?.code)
      user.token && history.push(firstNav?.linkUrl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.token])

  const beforeLogin = async () => {
    //拿到用户信息
    await loginService.getUser({ token: token }).then(result => {
      const { retData, retCode, retMsg } = result
      let { menus, resources, roles, shops, ...userInfo } = retData ?? {};
      if (retCode === 200) {
        console.log(retData)
        if (retCode !== 200) {
          message.warning("获取用户信息失败: " + retMsg)
        }
        const menu = menus?.[CLIENT_APP_CODE]
        if (menu) {
          console.log("本用户配置本系统菜单 : ", menu)
          // setMenuData(menu)
          setMenuData(MenuData)
        } else {
          return message.warning("该登录用户未授权中汽北消使用权限: " + retMsg)
        }
        setUser({ ...user, ...result.retData, token, loginType: 'token' })
        // loginService.appLogin(result.retData.userCode as string).then(res => console.log(res.retMsg))
        // history.push('/')
      } else {
        message.warning("获取用户信息失败")
      }
    });

  }


  return (
    <div className="login">
      <div className="caseBlanche">
        <div className="rond">
          <div className="test"></div>
        </div>
        <div className="load">
          <p>loading</p>
        </div>
      </div>
    </div>
  )

}

export default TokenLogin;