import React, { useEffect } from "react";
import { useJobDetailModel } from "./DetailModel";
import { InfoBox } from "./InfoBox";
import { YhPageHeader } from "../../../components/YhPageHeader";
import { useJobSummaryModel } from "../summary/SummaryModel";
import { EditModeEnum } from "../../../types/FmsEnums";

export const JobDetail = () => {
    const {
        selectedRowId,
        setSelectedRowId
    } = useJobSummaryModel()

    const {
        editMode,
        updateState,
    } = useJobDetailModel();


    useEffect(() => {
        if (editMode === EditModeEnum.add) {
            setSelectedRowId('')
        } else {
        }
        return () => {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateState, selectedRowId, editMode])


    return (
        <>
            <YhPageHeader title="岗位详情" />
            <InfoBox />
        </>
    )
}
