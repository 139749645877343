import React, { useRef, useState } from "react";
import { createModel } from "hox";
import { IJobFields } from "../JobTypes";
import { jobService } from "../JobService";
import { useSessionStorageState } from "ahooks";
import { EditModeEnum } from "../../../types/FmsEnums";
import { useJobSummaryModel } from "../summary/SummaryModel";
import { globalPrompt } from "../../../components/YhMessage";
import { IYhFormRef } from "../../../components/YhFormBox/Types";
import useNavModel from "../../../model/navModel";
import { getFormValidatorErrText } from "../../../utils/utils";


export const useJobDetailModel = createModel(function () {
    const {
        selectedRowId,
        selectedData
    } = useJobSummaryModel()
    const { selectedNavId, replaceNav } = useNavModel();
    const formRef = useRef<IYhFormRef>();


    // 刷新数据
    const [updateState, setUpdateState] = useState(false)

    // 编辑模式
    const [editMode, setEditMode] = useSessionStorageState<EditModeEnum>('job.edit.mode', EditModeEnum.add)



    //关闭当前页
    const onClose = () => {
        replaceNav(selectedNavId, "ff-06");
    }

    //表单提交
    const onSubmit = async () => {
        const formData = await formRef.current?.validateFields()
        formRef.current?.validateFields().then(res => {
            let params: IJobFields = {
                ...formData,
                describeHtml: formData.describe?.toHTML(),
                describe: formData.describe?.toText(),
            }
            if (editMode === EditModeEnum.add) {
                jobService.insert(params).then(result => {
                    let { retData, retMsg } = result
                    if (retData) {
                        globalPrompt('message', { type: 'success', text: '新增成功！' })
                        onClose()
                    } else {
                        globalPrompt('message', { type: 'warning', text: '新增失败！' + retMsg })
                    }
                })
            } else {
                jobService.update({ ...params, id: selectedRowId }).then(result => {
                    let { retData, retMsg } = result
                    if (retData) {
                        globalPrompt('message', { type: 'success', text: '修改成功！' })
                        onClose()
                    } else {
                        globalPrompt('message', { type: 'warning', text: '修改失败！' + retMsg })
                    }
                })
            }
        }).catch(err => {
            globalPrompt('message', { type: 'warning', text: getFormValidatorErrText(err) })
        })
    }


    return {

        /***field***/
        updateState,
        editMode,
        formRef,
        selectedData,

        /***method***/
        setUpdateState,
        setEditMode,
        onClose,
        onSubmit
    }
});
