import React from "react";
import { Popconfirm, Table, Image } from "antd";
import { ColumnsType } from "antd/es/table";
import { useNewsSummaryModel } from "./SummaryModel";
import { INewsFields } from "../NewsTypes";
import { EditModeEnum } from "../../../types/FmsEnums";
import useNavModel from "../../../model/navModel";
import moment from "moment";
import { useNewsDetailModel } from "../detail/DetailModel";
import { YhBut } from "../../../components/YhButton";




export function DataTable() {
    const { addNav } = useNavModel();
    const {
        dataSource,
        pgCfg,
        loadData,
        setSelectedRowId,
        setSelectedData,
        onRemove,
    } = useNewsSummaryModel();
    const {
        setEditMode
    } = useNewsDetailModel()

    const columns: ColumnsType<INewsFields> = [
        {
            align: 'center',
            title: '序号',
            width: 60,
            render: (v, r, i) => i + 1
        },
        {
            align: "center",
            title: '文章标题',
            dataIndex: 'title',
        },
        {
            align: "center",
            title: '发布日期',
            dataIndex: 'publishTime',
        },
        {
            align: 'center',
            title: '操作',
            width: 150,
            render: (value, record, index) => (
                <>
                    <YhBut
                        typeName="icon-chakan"
                        text="查看"
                        style={{ color: "#1296DB", fontSize: "18px" }}
                        click={() => onRowEdit(record, EditModeEnum.look)}
                    />
                    <YhBut
                        typeName="icon-bianji1"
                        text="编辑"
                        style={{ color: "#028E19", fontSize: "18px" }}
                        click={() => onRowEdit(record, EditModeEnum.edit)}
                    />
                    <YhBut
                        typeName="icon-shanchu"
                        text="删除"
                        style={{ color: "red", fontSize: "18px" }}
                        click={() => onRemove(record.id as string)}
                    />
                </>
            )
        },

    ];

    const onRowEdit = (data: INewsFields, mode: EditModeEnum) => {
        setSelectedRowId(data.id)
        setSelectedData(data)
        setEditMode(mode)
        addNav("ff-02-01");
    }
    return (
        <Table
            style={{ cursor: "pointer" }}
            // rowSelection={{
            //     type: "radio",
            //     onChange: (selectedRowKeys: React.Key[]) => {
            //         setSelectedRowKeys(selectedRowKeys);
            //     },
            //     selectedRowKeys
            // }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            // onRow={record => {
            //     return {
            //         onClick: event => {
            //             // if (selectedRowKeys.length > 0 && selectedRowKeys[0] === record.key) {
            //             //     setSelectedRowKeys([]);
            //             // } else {
            //             //     setSelectedRowKeys([record.key]);
            //             // }
            //         },
            //     };
            // }}
            scroll={{ x: 'max-content' }}
        />
    )
}
