import React, { FC } from "react";

import { Col } from "antd";
import { FormItemTypeEnum, IFormItem } from "../Types";
import { __FormInput as FormInput } from "./FormInput";
import { __FormDatePicker as FormDatePicker } from "./FormDatePicker";
import { __FormSwitch as FormSwitch } from "./FormSwitch";
import { __FormSelect as FormSelect } from "./FormSelect";
import { __FormCascader as FormCascader } from "./FormCascader";
import { __FormJsonView as FormJsonView } from "./FormJsonView";
import { __FormTreeSelect as FormTreeSelect } from "./FormTreeSelect";
import { __FormNumber as FormNumber } from "./FormNumber";
import { __FormPassword as FormPassword } from "./FormPassword";
import { __FormTextArea as FormTextArea } from "./FormTextArea";
import { __FormUpload as FormUpload } from "./FormUpload";
export const __FormItem: FC<IFormItem> = (props) => {

    return <Col span={props.span ?? 6} key={props.fieldName} {...props}>
        {
            (() => {
                switch (props.type) {
                    case FormItemTypeEnum.Input:
                        return <FormInput {...props} />;
                    case FormItemTypeEnum.Datepicker:
                        return <FormDatePicker {...props} />;
                    case FormItemTypeEnum.Switch:
                        return <FormSwitch {...props} />;
                    case FormItemTypeEnum.Select:
                        return <FormSelect {...props} />;
                    case FormItemTypeEnum.JsonView:
                        return <FormJsonView {...props} />;
                    case FormItemTypeEnum.TreeSelect:
                        return <FormTreeSelect {...props} />;
                    case FormItemTypeEnum.InputNumber:
                        return <FormNumber {...props} />;
                    case FormItemTypeEnum.Password:
                        return <FormPassword {...props} />;
                    case FormItemTypeEnum.TextArea:
                        return <FormTextArea {...props} />;
                    case FormItemTypeEnum.Upload:
                        return <FormUpload {...props} />;
                    case FormItemTypeEnum.Cascader:
                        return <FormCascader {...props} />;
                    case FormItemTypeEnum.Other:
                        return props.formItem
                    default:
                        return <></>;
                }
            })()
        }
    </Col>
}
