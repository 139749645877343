import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useNavModel from "../model/navModel";
// import menuData from "../data/menuData.json";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Tag } from "antd";
import { IMenuNode } from "./FmsMenu";
import useGlobalModel from "@src/model/globalModel";

const NavBox = SortableContainer(
    (props: any) => (
        <div
            className="layout-section-nav"
            {...props}>
            {props.children}
        </div>
    )
);
const Nav = SortableElement(
    (props: any) => <>{props.children}</>
);

function getNavSelectedMenu(menuData: IMenuNode[], menuId: String) {
    function filterMenus(menus: IMenuNode[]): IMenuNode | undefined {
        for (const menu of menus) {
            if (menu.code === menuId) return menu;
            if (menu.children && menu.children.length > 0) {
                const ret = filterMenus(menu.children)
                if (ret) return ret;
            }

        }
    }

    return filterMenus(menuData)
}

function FmsNav() {
    const history = useHistory();
    const location = useLocation();
    const { menuData } = useGlobalModel();

    const {
        navTabs,
        selectedNavId,
        getSelectedNavState,
        selectNav,
        closeNav,
        sortNav
    } = useNavModel();

    useEffect(() => {
        const page = getNavSelectedMenu(menuData, selectedNavId);
        if (page) {
            //路由是否变化
            if (location.pathname !== page.linkUrl && location.pathname !== "/login")
                // console.log(Nav.getSelectNode('state'), history, "nav切换")
                history.push({ pathname: page.linkUrl, state: getSelectedNavState() });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNavId]);
    return (
        <NavBox
            distance={20}
            transitionDuration={200}
            axis="x"
            onSortEnd={sortNav}>
            {
                menuData.length > 1 ? navTabs.map((item, index) => (
                    <Nav
                        key={item.code}
                        index={index}
                    >
                        <Tag
                            key={item.code}
                            closable={item.closeable === 0 && navTabs.length > 1}
                            className={item.code === selectedNavId ? 'tag-checked' : ""}
                            onClick={() => selectNav(item.code)}
                            onClose={() => closeNav(item.code)}
                        >
                            {item.name}
                        </Tag>
                    </Nav>
                )) : null
            }
        </NavBox>
    )
}

export default FmsNav;