import React, { memo, useState } from "react";
import { Button, Col, Form, message, Upload } from "antd";
import { YhBox } from "../../../components/YhBox";
import { YhForm } from "../../../components/YhFormBox";
import { FormItemTypeEnum, IYhFormItemsBox } from "../../../components/YhFormBox/Types";
import { useNewsDetailModel } from "./DetailModel";
import { EditModeEnum } from "../../../types/FmsEnums";
import { CategoryTypeDs } from "@src/types/FmsDs";
import { importPropsConfig, UploadInfo } from "@src/utils/importFile";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { loadFileUrl, uploadFileUrl } from "@src/utils/constants";
import BraftEditor from "braft-editor";
import 'braft-editor/dist/index.css'
import { globalPrompt } from "@src/components/YhMessage";
import { UploadFile } from "antd/lib/upload/interface";
import { getBase64 } from "@src/utils/utils";
import moment from "moment";
import { httpRequest } from "@src/utils/HttpRequest";
import axios from "axios";

export const InfoBox = () => {

    const {
        formRef,
        editMode,
        selectedData,
        onSubmit,
        onClose,
    } = useNewsDetailModel()

    const normFile = (e: any) => {
        console.log('Upload event:', e);
        if (e?.file.status === 'done' && e?.file.response.retCode === "200") {
            e.fileList[0] = { ...e.file, uid: e.file.response.retData }
        }
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as Blob) as string;
        }
        globalPrompt('modal', {
            icon: <></>,
            closable: true,
            title: '图片预览',
            footer: <></>,
            text: <img alt="example" style={{ width: '100%' }} src={file.url || file.preview} />
        })
    };

    const formItem: IYhFormItemsBox = {
        style: {
            padding: "20px 77px 20px 0",
            marginBottom: "15px"
        },
        disabled: editMode === EditModeEnum.look ? true : false,
        items: [
            {
                type: FormItemTypeEnum.Input,
                span: 12,
                fieldLabel: "文章标题",
                placeholder: "文章标题",
                fieldName: "title",
                rules: [{ required: true, message: "请输入文章标题" }]
            }, {
                type: FormItemTypeEnum.Input,
                span: 12,
                fieldLabel: "关键字",
                placeholder: "关键字",
                fieldName: "keyWord",
                rules: [{ required: true, message: "请输入关键字" }]
            }, {
                type: FormItemTypeEnum.Input,
                span: 12,
                fieldLabel: "描述",
                placeholder: "描述",
                fieldName: "describe",
                rules: [{ required: true, message: "请输入描述" }]
            }, {
                type: FormItemTypeEnum.Datepicker,
                span: 12,
                fieldLabel: "发布时间",
                placeholder: "发布时间",
                fieldName: "publishTime",
                rules: [{ required: true, message: "请选择发布时间" }]
            }, {
                type: FormItemTypeEnum.Other,
                span: 12,
                fieldLabel: "",
                placeholder: "",
                fieldName: "",
                formItem: (
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => {
                            return prevValues.fileList !== currentValues.fileList
                        }}
                    >
                        <Form.Item
                            name="image"
                            label="封面"
                            valuePropName="fileList"
                            rules={[{ required: true, message: "请上传图片" }]}
                            getValueFromEvent={normFile}
                        >

                            <Upload
                                action={uploadFileUrl}
                                listType="picture-card"
                                className="avatar-uploader"
                                maxCount={1}
                                onPreview={handlePreview}
                                disabled={editMode === EditModeEnum.look}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>上传图片</div>
                                </div>
                            </Upload>
                        </Form.Item>
                    </Form.Item>
                )
            }
        ]
    };

    const myUploadFn = (param: any) => {
        const formData = new FormData()
        formData.append('file', param.file)
        axios.post(uploadFileUrl, formData, {
            headers: { "Content-Type": "multipart/form-data;" },
            onUploadProgress(event) {
                param.progress(event.loaded / event.total * 100)
            },
        }).then(result => {
            console.log(JSON.stringify(result, null, 2));
            console.log(loadFileUrl(result.data.retData));
            console.log(loadFileUrl(result.data.retData));
            param.success({
                url: loadFileUrl(result.data.retData),
            })
        }).catch(error => {
            param.error({
                msg: '上传失败'
            })
        })
        // httpRequest.postFile('http://file-server.laoniuche.cn/file/uploadFile', formData).then(result => {
        //     debugger
        //     console.log(JSON.stringify(result, null, 2))
        //     param.success({
        //         url: 'http://file-server.laoniuche.cn/file/fetch?id=' + result.data.retData,
        //     })
        // })
        // const serverURL = 'http://file-server.laoniuche.cn/file/uploadFile'
        // const xhr = new XMLHttpRequest()
        // const fd = new FormData()

        // const successFn = (response: any) => {
        //     // 假设服务端直接返回文件上传后的地址
        //     // 上传成功后调用param.success并传入上传后的文件地址
        //     debugger
        //     param.success({
        //         url: xhr.responseText,
        //         meta: {
        //             id: 'xxx',
        //             title: 'xxx',
        //             alt: 'xxx',
        //             loop: true, // 指定音视频是否循环播放
        //             autoPlay: true, // 指定音视频是否自动播放
        //             controls: true, // 指定音视频是否显示控制栏
        //             poster: 'http://xxx/xx.png', // 指定视频播放器的封面
        //         }
        //     })
        // }

        // const progressFn = (event: any) => {
        //     // 上传进度发生变化时调用param.progress
        //     param.progress(event.loaded / event.total * 100)
        // }

        // const errorFn = (response: any) => {
        //     // 上传发生错误时调用param.error
        //     param.error({
        //         msg: 'unable to upload.'
        //     })
        // }

        // xhr.upload.addEventListener("progress", progressFn, false)
        // xhr.addEventListener("load", successFn, false)
        // xhr.addEventListener("error", errorFn, false)
        // xhr.addEventListener("abort", errorFn, false)

        // fd.append('file', param.file)
        // xhr.open('POST', serverURL, true)
        // xhr.send(fd)

    }

    return (
        <YhBox
            action={
                <>
                    {
                        editMode !== EditModeEnum.look ? <Button
                            type="link"
                            shape="round"
                            onClick={onSubmit}
                        > 保存 </Button> : undefined
                    }
                    <Button
                        type="link"
                        shape="round"
                        onClick={onClose}
                    > 取消 </Button>
                </>
            }
            style={{ padding: '20px' }}
        >

            <YhForm
                formRef={formRef}
                labelCol={{ span: 6 }}
                labelAlign="right"
                justify="start"
                disabled={editMode === EditModeEnum.look}
                formValues={editMode === EditModeEnum.add ? {} : {
                    ...selectedData,
                    image: selectedData?.image?.split(',').map(item => {
                        return {
                            uid: item,
                            url: loadFileUrl(item)
                        }
                    }),
                    content: BraftEditor.createEditorState(selectedData?.contentHtml),
                    publishTime: moment(selectedData?.publishTime)
                }}

                {...formItem}
            >
                <Col span={24} key="content">
                    <Form.Item
                        label="公告内容"
                        name="content"
                        labelCol={{
                            span: 3
                        }}
                        required={true}
                        rules={[
                            { required: true, message: "请输入公告内容" }
                        ]}
                    >
                        <BraftEditor
                            readOnly={editMode === EditModeEnum.look}
                            media={{ uploadFn: myUploadFn }}
                            style={{ border: "1px solid #ccc", borderRadius: "6px" }}
                            className="my-editor"
                            placeholder="请输入正文内容"
                        />
                    </Form.Item>
                </Col>
            </YhForm>
        </YhBox>
    )
}
