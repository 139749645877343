import React, { FC } from "react";
import { IFormItem } from "../Types";
import { Form, Select } from "antd";

export const __FormSelect: FC<IFormItem> = (props) => {
    return (
        <Form.Item
            label={props.fieldLabel}
            name={props.fieldName}
            rules={props.rules}
            labelCol={props.labelCol}
        >
            <Select
                mode={props.mode}
                disabled={props.disable}
                allowClear={props.allowClear}
                options={props.ds}
                onChange={props.onChange}
                filterOption={props.filterOption}
                optionLabelProp={props.optionLabelProp}
            />
        </Form.Item>
    )
}
