import React from "react";
import { Table, Image } from "antd";
import { ColumnsType } from "antd/es/table";
import { useMessageModel } from "./MessageModel";
import { IMessageFields } from "./MessageTypes";




export function DataTable() {
    const {
        dataSource,
        pgCfg,
        loadData,
    } = useMessageModel();

    const columns: ColumnsType<IMessageFields> = [
        {
            align: 'center',
            title: '序号',
            width: 60,
            render: (v, r, i) => i + 1
        },
        {
            align: "center",
            title: '车辆名称',
            width: 150,
            dataIndex: 'name',
        },
        {
            align: "center",
            title: '公司',
            width: 150,
            dataIndex: 'company',
        },
        {
            align: "center",
            title: '联系电话',
            width: 180,
            dataIndex: 'phone',
        },
        {
            align: "center",
            title: 'QQ',
            width: 160,
            dataIndex: 'qq',
        },
        {
            align: "center",
            title: '留言信息',
            width: 300,
            dataIndex: 'content',
        }

    ];

    return (
        <Table
            style={{ cursor: "pointer" }}

            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            scroll={{ x: 'max-content' }}
        />
    )
}
