import { IPage } from "../../types/FmsTypes";
import { httpRequest } from "../../utils/HttpRequest";
import { IProductFields, IProductSearch } from "./ProductTypes";

class ProductService {
    /**
     * 分页查询
     * @param param 
     * @returns 
     */
    page(param: IProductSearch) {
        const apiUrl = "/zqbx_api/product/page";
        return httpRequest
            .post<IPage<IProductFields>>(apiUrl, param);
    }
    /**
     * 新增
     * @param param 
     * @returns 
     */
    insert(param: IProductFields) {
        const apiUrl = "/zqbx_api/product/insert";
        return httpRequest
            .post<boolean>(apiUrl, param);
    }
    /**
     * 编辑
     * @param param 
     * @returns 
     */
    update(param: IProductFields) {
        const apiUrl = "/zqbx_api/product/update";
        return httpRequest
            .post<boolean>(apiUrl, param);
    }
    /**
     * 查看单条
     * @param id string
     * @returns 
     */
    findOne(id: string) {
        const apiUrl = `/zqbx_api/product/one/${id}`;
        return httpRequest
            .get<IProductFields>(apiUrl);
    }
    /**
     * 删除
     * @param id string
     * @returns 
     */
    remove(id: string) {
        const apiUrl = `/zqbx_api/product/remove/${id}`;
        return httpRequest
            .get<IProductFields>(apiUrl);
    }

}

export const productService = new ProductService();
