import React, { memo } from "react";
import { Button, Col, Form } from "antd";
import { YhBox } from "../../../components/YhBox";
import { YhForm, YhFormBox } from "../../../components/YhFormBox";
import { FormItemTypeEnum, IYhFormItemsBox } from "../../../components/YhFormBox/Types";
import { useJobDetailModel } from "./DetailModel";
import { EditModeEnum } from "../../../types/FmsEnums";
import moment from "moment";
import BraftEditor from "braft-editor";
import 'braft-editor/dist/index.css'

export const InfoBox = () => {

    const {
        formRef,
        editMode,
        selectedData,
        onSubmit,
        onClose,
    } = useJobDetailModel()

    const formItem: IYhFormItemsBox = {
        style: {
            padding: "20px 77px 20px 0",
            marginBottom: "15px"
        },
        disabled: editMode === EditModeEnum.look ? true : false,
        items: [
            {
                type: FormItemTypeEnum.Input,
                span: 12,
                fieldLabel: "名称",
                placeholder: "名称",
                fieldName: "name",
                rules: [{ required: true, message: "请输入名称" }]
            }, {
                type: FormItemTypeEnum.Input,
                span: 12,
                fieldLabel: "部门",
                placeholder: "部门",
                fieldName: "branch",
                rules: [{ required: true, message: "请输入部门" }]
            }, {
                type: FormItemTypeEnum.Input,
                span: 12,
                fieldLabel: "工作地点",
                placeholder: "工作地点",
                fieldName: "address",
                rules: [{ required: true, message: "请输入工作地点" }]
            }, {
                type: FormItemTypeEnum.InputNumber,
                min: 1,
                span: 12,
                fieldLabel: "招聘人数",
                placeholder: "招聘人数",
                fieldName: "number",
                rules: [{ required: true, message: "请输入招聘人数" }]
            }, {
                type: FormItemTypeEnum.Datepicker,
                span: 12,
                fieldLabel: "发布时间",
                placeholder: "发布时间",
                fieldName: "publishTime",
                rules: [{ required: true, message: "请输入发布时间" }]
            }
        ]
    };


    return (
        <YhBox
            action={
                <>
                    {
                        editMode !== EditModeEnum.look ? <Button
                            type="link"
                            shape="round"
                            onClick={onSubmit}
                        > 保存 </Button> : undefined
                    }
                    <Button
                        type="link"
                        shape="round"
                        onClick={onClose}
                    > 取消 </Button>
                </>
            }
            style={{ padding: "20px" }}
        >
            <YhForm
                formRef={formRef}
                labelCol={{ span: 6 }}
                labelAlign="right"
                justify="start"
                disabled={editMode === EditModeEnum.look}
                formValues={editMode === EditModeEnum.add ? {
                } : {
                    ...selectedData,
                    describe: BraftEditor.createEditorState(selectedData?.describeHtml),
                    publishTime: moment(selectedData?.publishTime)
                }}
                {...formItem}
            >
                <Col span={24} key="describe">
                    <Form.Item
                        label="公告内容"
                        name="describe"
                        labelCol={{
                            span: 3
                        }}
                        required={true}
                        rules={[
                            { required: true, message: "请输入岗位详情" }
                        ]}
                    >
                        <BraftEditor
                            readOnly={editMode === EditModeEnum.look}
                            style={{ border: "1px solid #ccc", borderRadius: "6px" }}
                            className="my-editor"
                            placeholder="请输入岗位详情"
                        />
                    </Form.Item>
                </Col>
            </YhForm>
        </YhBox>
    )
}
