import React, { FC } from "react";
import { IFormItem } from "../Types";
import { Cascader, Form } from "antd";

export const __FormCascader: FC<IFormItem> = (props) => {
    return (
        <Form.Item
            label={props.fieldLabel}
            name={props.fieldName}
            rules={props.rules}
            labelCol={props.labelCol}
        >
            <Cascader
                disabled={props.disable}
                allowClear={props.allowClear}
                options={props.cds}
                onChange={props.onChange}
            />
        </Form.Item>
    )
}
