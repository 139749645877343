import React, { FC } from "react";
import { IFormItem } from "../Types";
import { Form, Input } from "antd";

export const __FormTextArea: FC<IFormItem> = (props) => {
    return (
        <Form.Item
            label={props.fieldLabel}
            name={props.fieldName}
            rules={props.rules}
            hidden={props.hidden}
            labelCol={props.labelCol}
        >
            <Input.TextArea
                showCount={true}
                rows={props.rows}
                disabled={props.disable ?? false}
                allowClear={props.allowClear}
            />
        </Form.Item>
    )
}