import { IPage } from "../../types/FmsTypes";
import { httpRequest } from "../../utils/HttpRequest";
import { IJobFields, IJobSearch } from "./JobTypes";

class JobService {
    /**
     * 分页查询
     * @param param 
     * @returns 
     */
    page(param: IJobSearch) {
        const apiUrl = "/zqbx_api/job/page";
        return httpRequest
            .post<IPage<IJobFields>>(apiUrl, param);
    }
    /**
     * 新增
     * @param param 
     * @returns 
     */
    insert(param: IJobFields) {
        const apiUrl = "/zqbx_api/job/insert";
        return httpRequest
            .post<boolean>(apiUrl, param);
    }
    /**
     * 编辑
     * @param param 
     * @returns 
     */
    update(param: IJobFields) {
        const apiUrl = "/zqbx_api/job/update";
        return httpRequest
            .post<boolean>(apiUrl, param);
    }
    /**
     * 查看单条
     * @param id string
     * @returns 
     */
    findOne(id: string) {
        const apiUrl = `/zqbx_api/job/one/${id}`;
        return httpRequest
            .get<IJobFields>(apiUrl);
    }
    /**
     * 删除
     * @param id string
     * @returns 
     */
    remove(id: string) {
        const apiUrl = `/zqbx_api/job/remove/${id}`;
        return httpRequest
            .get<IJobFields>(apiUrl);
    }

}

export const jobService = new JobService();
