import React, { FC } from "react";
import { ISearchField, ISearchProp, SearchTypeEnum } from "../Types";
import { SearchInput } from "./SearchInput";
import { SearchDatepicker } from "./SearchDatepicker";
import { SearchRangePicker } from "./SearchRangePicker";
import { SearchSelect } from "./SearchSelect";
import { Col } from "antd";
import { SearchButton } from "./SearchButton";
import { SearchTreeSelect } from "./SearchTreeSelect";

type PropType = ISearchProp & ISearchField;
export const SearchItem: FC<PropType> = (props) => {

    return <Col span={props.span ?? 6} xxl={props.xxl ?? 4} lg={props.lg} key={props.fieldName}>
        {
            (() => {
                switch (props.type) {
                    case SearchTypeEnum.Input:
                        return <SearchInput {...props} />;
                    case SearchTypeEnum.Datepicker:
                        return <SearchDatepicker {...props} />;
                    case SearchTypeEnum.RangePicker:
                        return <SearchRangePicker {...props} />;
                    case SearchTypeEnum.Select:
                        return <SearchSelect {...props} />;
                    case SearchTypeEnum.TreeSelect:
                        return <SearchTreeSelect {...props} />;
                    case SearchTypeEnum.Button:
                        return <SearchButton {...props} />;
                    default:
                        return <></>;
                }
            })()
        }
    </Col>
}
