import React, { useState, useEffect } from "react";
import { Menu, Button } from "antd";
// import menuData from "../data/menuData.json";
import useNavModel from "../model/navModel";
import useGlobalModel from "../model/globalModel";
import FontIcon from "../components/YhIcon";
import { Link } from "react-router-dom";
import { forFind } from "../utils/utils";
//定义菜单参数类型
export interface IMenuNode {
    code: string,
    name: string,
    linkUrl: string,
    iconfont: string,
    pcode: string,
    closeable: number,
    isShow: number,
    children: IMenuNode[]
}

const { SubMenu } = Menu;

function FmsMenu() {
    const { addNav, selectedNavId } = useNavModel();
    const { cm, toggleMenu, menuData } = useGlobalModel();

    const [selectedMenuKey, setSelectedMenuKey] = useState<string>("");
    const [openKeys, setOpenKeys] = useState<string[]>(["1"]);

    //对应详情展示及选中菜单展开
    useEffect(() => {
        let menuInfo = forFind(menuData, selectedNavId, 'code') as IMenuNode;
        let selectedMenuId: string = menuInfo?.isShow === 0 ? menuInfo?.code : menuInfo?.pcode ?? "";
        let newOpenKey: string = selectedMenuId.split('-')[0];
        if (openKeys.indexOf(newOpenKey) === -1) {
            // let newOpenKeys: string[] = [...openKeys];
            let newOpenKeys: string[] = [];
            newOpenKeys.push(newOpenKey);
            setOpenKeys(newOpenKeys)
        }
        setSelectedMenuKey(selectedMenuId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNavId]);

    //选中的submenu Key
    const onOpenChange = (keys: React.Key[]) => {
        setOpenKeys(keys as string[])
    };
    //菜单每项选渲染
    const MenuItem = (node: IMenuNode): JSX.Element | null => (
        <Menu.Item key={node.code} icon={<FontIcon type={node.iconfont} />} onClick={() => addNav(node.code)}>
            <Link to={{ pathname: node.linkUrl, state: { id: node.code } }}>{node.name}</Link>
        </Menu.Item>
    )
    //菜单渲染
    const AppSubMenu = (node: IMenuNode): JSX.Element | null => {
        if (node.isShow === 0 && node?.children?.length > 0) {
            let filterNode = node.children.filter(item => item.isShow === 0);
            return filterNode.length > 0 ?
                <SubMenu
                    key={node.code}
                    icon={<FontIcon type={node.iconfont} />}
                    title={<span>{node.name}</span>}
                >
                    {
                        node.children.map(el => AppSubMenu(el))
                    }
                </SubMenu> : MenuItem(node)
        } else {
            return node.isShow === 0 ? MenuItem(node) : null
        }
    }


    return (
        <>
            <Menu
                mode="inline"
                theme="light"
                className="layout-sider-menu"
                onOpenChange={onOpenChange}
                openKeys={openKeys}
                selectedKeys={[selectedMenuKey]}
            >
                {
                    menuData.map(node => AppSubMenu(node))
                }
            </Menu>
            <Button onClick={() => toggleMenu()} className={`block  ${cm.collapsed ? 'layout-sider-menu-button--small' : 'layout-sider-menu-button'}`} type="link" shape="round" icon={<FontIcon type="icon-mulu" />} />
        </>
    )
}

export default FmsMenu;