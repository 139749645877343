import React, { FC } from "react";
import { IFormItem } from "../Types";
import { Form, Switch } from "antd";

export const __FormSwitch: FC<IFormItem> = (props) => {
    return (
        <Form.Item
            label={props.fieldLabel}
            name={props.fieldName}
            rules={props.rules}
            labelCol={props.labelCol}
            hidden={props.hidden}
            valuePropName={props.valuePropName ?? 'checked'}
        >
            <Switch
                checkedChildren={props.checkedChildren || "开启"}
                unCheckedChildren={props.unCheckedChildren || "关闭"}
                disabled={props.disable}
                onChange={props.onChange}
            />
        </Form.Item>
    )
}