import { Button, Input } from "antd";
import { YhBox } from "@src/components/YhBox";
import { useResourceModel } from "./ResourceModel";
import { useEffect } from "react";

export const VideoUrl = () => {

  const { videoUrl, setVideoUrl, onUpdate, loadData } = useResourceModel()

  useEffect(() => {
    loadData('video').then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <YhBox action={<Button type="link" shape="round" onClick={() => onUpdate('video')} > 保存 </Button>}
      style={{ padding: '20px' }}
    >
      <Input placeholder="宣传视频地址" onChange={e => setVideoUrl(e.target.value)} value={videoUrl} />
      <iframe width="500px" height="300px" style={{ margin: '50px' }} frameBorder="0" src={videoUrl} referrerPolicy="unsafe-url" allowFullScreen></iframe>
    </YhBox>
  )
}
