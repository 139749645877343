import React from "react";
import {ISearchField, ISearchProp} from "../Types";
import {Button} from "antd";

type PropType = ISearchProp & ISearchField;
export const SearchButton = (props: PropType) => {
    return <Button shape="round" style={{width: "100%"}} onClick={props.onClick}>
        {props.placeholder}
    </Button>

}
